export default {
  'comercial': () => import('@/components/relatorios/forms/v1/Comercial.vue'),
  'reclamacao': () => import('@/components/relatorios/forms/v1/Reclamacao.vue'),
  'tec-al': () => import('@/components/relatorios/forms/v1/TecAl.vue'),
  'comercial-v2': () => import('@/components/relatorios/forms/v2/ComercialV2.vue'),
  'servicos-v2': () => import('@/components/relatorios/forms/v2/ServicosV2.vue'),
  'alimentadores': () => import('@/components/relatorios/forms/v2/Alimentadores.vue'),
  'subestacoes': () => import('@/components/relatorios/forms/v2/Subestacoes.vue'),
  'linhas-de-distribuicao': () => import('@/components/relatorios/forms/v2/LinhasDeDistribuicao.vue'),
};

